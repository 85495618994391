.ce_rsce_trennerZaehler {
    position: relative;
    min-height: 700px;
    display: flex;
    align-items: center;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(4,66,131,.8);
        mix-blend-mode: multiply;
    }
    .paralax-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
        overflow: hidden;
    }
    .container {
        position: relative;
        max-width: $maxWidth;
        margin: 0 auto;
        width: 100%;
        padding: $paddingMobile;
        z-index: 1;
        .container-inner {
            h2 {
                color: $white;
                text-align: center;
                &::after {
                    left: 50%;
                    transform: translate3d(-50%,-50%,0);
                }
            }
            &.zaehler {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .item {
                    flex: calc(20% - 2rem);
                    width: calc(20% - 2rem);
                    min-width: 240px;
                    text-align: center;
                    &:last-child {
                        flex: calc(40% - 2rem);
                        width: calc(40% - 2rem);
                       
                        
                    }
                    .item-inner {
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        width: 100%;
                        p {
                            color: $white;
                            margin: 1rem 0;
                        }
                        .zahl {
                            color: $secondary;
                            font-size: 3rem;
                            font-family: 'Mont';
                            font-weight: 700;
                            margin: 0;
                        }
                    }
                   
                }
            }
        }
    }
}

@mixin rsce_trennerZaehler_tablet {
    .ce_rsce_trennerZaehler {
        .container {
            .container-inner.zaehler {
                .item {
                    .item-inner {
                        .zahl {
                            font-size: 4.5rem;
                            margin: 1rem 0;
                        }
                    }
                }
            }
        }
    }
}

@mixin rsce_trennerZaehler_desktop_s {
    .ce_rsce_trennerZaehler {
        .container {
            padding: $paddingDesktop;
            .container-inner {
                h2 {
                    text-align: left;
                    &::after {
                        left: 0;
                        transform: unset;
                    }
                }
                &.zaehler {
                    .item {
                        &:first-of-type {
                            .item-inner {
                                width: 40%;
                            }
                        }
                        .item-inner {
                            .zahl {
                                font-size: 5rem;
                            }
                        }
                        &:last-child {
                            display: flex;
                            justify-content: end;
                            .item-inner {
                                width: 60%;
                            }
                        }
                    }
                }
            }
        }
    }
}