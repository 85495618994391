.ce_rsce_sortiment {
    .container {
        padding: $paddingMobile;
        max-width: $maxWidth;
        margin: 0 auto;
        .container-inner {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 2rem;
            .produkt {
                flex: calc(33.33% - 2rem);
                width:  calc(33.33% - 2rem);
                min-width: 250px;
                .heading {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    p {
                        font-family: 'Mont';
                        font-weight: 700;
                        font-size: 1.4rem;
                        line-height: 2rem;
                        color: $primary;
                        margin-top: 0rem;
                        margin-bottom: 0rem;
                    }
                }
                a {
                    font-weight: bold;
                    text-decoration: underline;
                }
            }
        }
    }
}

@mixin rsce_sortiment_tablet {
    .ce_rsce_sortiment {
        .container {
            padding: $paddingDesktop;
            .container-inner {
                gap: 2rem;
                .produkt {
                    flex: calc(33.33% - 2rem);
                    width:  calc(33.33% - 2rem);
                    max-width: calc(33.33% - 2rem);
                    margin-bottom: 3rem;
                }
            }
        }
    }
}