/*
---
name: 1. Introduction
category: Globals
---
Die `variables.scss` beinhaltet die Definitionen der Globalen Variablen. 
Diese sind mit [`!default`](https://sass-lang.com/documentation/variables#default-values) angelegt - 
d.h. bereits definierte Variablen mit dem gleichen Namen werden nicht überschrieben sondern übernommen.
*/
/*
---
name: 2. Pfade
category: Globals
---
Die Pfade für Theme Ordner, Fonts & Bilder

Die Pfade haben keinen trailing slash und sind als SCSS und CSS variablen verfügbar:

SCSS       | Beschreibung
-----------|-------------
$themePath | Der Pfad zum Theme Ordner
$fontPath  | Der Pfad zum Font Ordner
$imgPath   | Der Pfad zum Bilder Ordner
*/
@import "config-importer.js"; // Import config.json
$themePath: "/files/themes/#{$kunde}";
$fontPath: "#{$themePath}/fonts";
$imgPath: "#{$themePath}/images";

/*
---
name: 3. Farben
category: Globals
---
Die zentralen Farbdefinitionen des Projekts.
Wir unterscheiden hier zwischen Standardfarben die von der Boilerplate mitgeliefert werden und 
Projektspeziefischen "Theme Farben".
*/
/*
---
name: 3. Farben
category: Globals
---
### Standardfarben
Die Standradfarben die von der Boilerplate geliefert werden beinhalten enen Satz von Grautönen und 
die normale Bootstrap Farbpallette.

Die Farben sind als SCSS und CSS Variablen verfügbar:

SCSS       	| CSS               	| Beschreibung
------------|-----------------------|-------------
$white		| var(--col-white)		| Weiss
$gray-100	| var(--col-gray-100)	| 10%-iges Grau
$gray-200	| var(--col-gray-200)	| 20%-iges Grau
$gray-300	| var(--col-gray-300)	| 30%-iges Grau
$gray-400	| var(--col-gray-400)	| 40%-iges Grau
$gray-500	| var(--col-gray-500)	| 50%-iges Grau
$gray-600	| var(--col-gray-600)	| 60%-iges Grau
$gray-700	| var(--col-gray-700)	| 70%-iges Grau
$gray-800	| var(--col-gray-800)	| 80%-iges Grau
$gray-900	| var(--col-gray-900)	| 90%-iges Grau
$black		| var(--col-black)		| Schwarz
$blue		| var(--col-blue)		| Blau
$indigo		| var(--col-indigo)		| Indigo
$purple		| var(--col-purple)		| Lila
$pink		| var(--col-pink)		| Pink
$red		| var(--col-red)		| Rot
$orange		| var(--col-orange)		| Orange
$yellow		| var(--col-yellow)		| Gelb
$green		| var(--col-green)		| Grün
$teal		| var(--col-teal)		| Blaugrün
$cyan		| var(--col-cyan)		| Schwarzblau
*/
//Greyscale
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
//Bootstrap Colors
$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;


/*
---
name: 3. Farben
category: Globals
---
### Theme Farben
Die Theme Farben haben Standardwerte basierend auf den Standardfarben, 
sollten aber in der Regel je nach Design überschrieben werden.

Die Farben sind als SCSS und CSS Variablen verfügbar:

SCSS       	| CSS                		| Beschreibung
-----------	|---------------------------|-------------
$primary   	| var(--theme-primary) 		| Die primäre Farbe des Themes
$scondary  	| var(--theme-secondary)	| Die Sekundäre Farbe des Themes
$success	| var(--theme-success)		| Farbe um einen Erfolg auszuweisen
$info		| var(--theme-info)			| Farbe um eine Information auszuweisen
$warning	| var(--theme-warning)		| Warnfarbe
$danger		| var(--theme-danger)		| Farbe um eine Gefahr auszuweisen
$light		| var(--theme-light)		| Helle Farbe des Themes
$dark		| var(--theme-dark)			| Dunkle Farbe des Themes
*/
$primary:       #054794 !default;
$secondary:     #FCEB18 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$gray:          #F4F4F4!default;
$gruen:         #8DCA47!default;

/*
---
name: 4. Breakpoints
category: Globals
---
Zentrale Breakpoint Definitionen.

Die Breakpoints basieren auf gängigen Bildschirmgrößen. In den meisten Fällen reichen sind die vordefinierten 
Breakpoints aus, bei Bedarf können aber beliebig zusätzliche Breakpoints definiert werden.

Die Breakpoints sind als SCSS und CSS Variablen verfügbar:

SCSS       | CSS                    | Beschreibung
-----------|------------------------|-------------
$mobile_s  | 	var(--bp-mobile_s)  |  iPhone SE
$mobile    | 	var(--bp-mobile)    |  iPhone X
$mobile_l  | 	var(--bp-mobile_l)  |  Phablets
$tablet_s  | 	var(--bp-tablet_s)  |  iPad Mini
$tablet    | 	var(--bp-tablet)    |  iPad
$tablet_l  | 	var(--bp-tablet_l)  |  iPad Pro/Air
$desktop_s | 	var(--bp-desktop_s) |  Small Laptops
$desktop   | 	var(--bp-desktop)   |  Laptop/Desktop
$desktop_l | 	var(--bp-desktop_l) |  32" iMac
*/
$mobile_s: 	320px !default;
$mobile: 	360px !default;
$mobile_l: 	420px !default;
$tablet_s: 	640px !default;
$tablet: 	768px !default;
$tablet_l: 	1024px !default;
$desktop_s: 1280px !default;
$desktop: 	1920px !default;
$desktop_l: 2560px !default;

/*
---
name: 5. Typographie
category: Globals
---
Globale Einstellunngen für die Typographie des Projekts

### Font
SCSS            		| Beschreibung
------------------------|--------------------------------------------------------------
$theme-font-family		| Die Hauptschriftart des Themes
$font-family-base		| Gängige serifenlose Systemschriften als Rückfalllösung
$font-family-monospace	| Monospace Systemschriften

### Colors
SCSS            | Beschreibung
----------------|--------------------------------------------------------------
$font_1         | Die Hauptschriftart
$modularScale   | Die modulare Skala zum skalieren der Überschriften (z.B. 1.2)
$mainLineHeight | Haupt Line height als basis für den vertikalen Rhytmus
$minFontSize    | Die Minimale Fontgröße für fluid type
$maxFontSize    | Die maximale Fontgröße für fluid type
$minFontWidth   | Die minimale Breite für fluid type
$maxFontWidth   | Die maximale Breite für fluid type

### Weights
SCSS            | Beschreibung
----------------|--------------------------------------------------------------
$fwThin         | Fontstärke thin/100
$fwExtralight   | Fontstärke extralight/200
$fwLight        | Fontstärke light/300
$fwRegular      | Fontstärke regular/400
$fwMedium       | Fontstärke medium/500
$fwSemibold     | Fontstärke semibold/600
$fwBold         | Fontstärke bold/700
$fwExtrabold    | Fontstärke extrabold/800
$fwBlack        | Fontstärke black/900

### Sizing
SCSS            | Beschreibung
----------------|--------------------------------------------------------------
$modularScale   | Die modulare Skala zum skalieren der Überschriften (z.B. 1.2)
$mainLineHeight | Haupt Line height als basis für den vertikalen Rhytmus
$minFontSize    | Die Minimale Fontgröße für fluid type
$maxFontSize    | Die maximale Fontgröße für fluid type
$minFontWidth   | Die minimale Breite für fluid type
$maxFontWidth   | Die maximale Breite für fluid type
*/
//Font
$font-family-monospace:	SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:	-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$theme-font-family: "ClearSans" !default;

//Colors
$fontColor: 		$gray-800 !default;
$headings-color: 	$primary !default;
$fontColorMuted: 	$gray-600!default;

//Links
$link-color: 			$primary !default;
$link-decoration: 		none !default;
$link-hover-color: 		darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

//Font Weights
$fwThin: 	                100 !default;
$fwExtralight:              200 !default;
$fwLight: 		            300 !default;
$fwRegular: 	            400 !default;
$fwMedium: 	                500 !default;
$fwSemibold: 	            600 !default;
$fwBold: 	                700 !default;
$fwExtrabold: 	            800 !default;
$fwBlack: 	                900 !default;

//Sizing
$modularScale: 		1.25 !default;
$mainLineHeight: 	1.5 !default;
$minFontSize: 		18px !default;
$maxFontSize: 		18px !default;
$minFontWidth: 		$mobile !default;
$maxFontWidth: 		$desktop-l !default;

/*
---
name: 6. Layout
category: Globals
---
Globale Einstellungen für das Layout.

Die Einstellungen sind als SCSS und CSS variablen verfügbar:

SCSS          | Beschreibung
--------------|-------------
$maxContWidth | Die maximale Contentbreite
$mainPadding  | Die Grunddefinition für Padding
*/

$mainPadding: 	    1.5rem !default;
$elementSpacing:    6rem !default;

$maxWidth: 	    calc(73rem + 4rem) !default;
$paddingMobile: 2rem 2rem;
$paddingDesktop: 3rem 2rem;
