.ce_rsce_hochSlider {
    background-color: $gray;
    padding-bottom: 4rem;
    .container.text {
        padding: $paddingMobile;
        max-width: $maxWidth;
        margin: 0 auto;
    }

    .swiper-container {
        padding: 2rem 2rem 4rem 2rem;
        position: relative;
        overflow: clip;
        .swiper-wrapper {
            .swiper-slide {
                position: relative;
                max-height: 690px;
                min-height: 450px;
                overflow: hidden;
                display: grid;
                &:hover {
                    img {
                        transform: scale(1.05);
                    }
                    &::after {
                        opacity: 0;
                    }
                    .text {
                        opacity: 0;
                    }
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: transform .5s cubic-bezier(0.3, 0.05, 1, 0.1);
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(6,71,148, .6);
                    transition: opacity .5s cubic-bezier(0.3, 0.05, 1, 0.1);
                }
                .text {
                    position: absolute;
                    top: 1rem;
                    left: 1rem;
                    z-index: 1;
                    transition: opacity .5s cubic-bezier(0.3, 0.05, 1, 0.1);
                    h2 {
                        text-transform: uppercase;
                        color: $white;
                        font-size: 1.2rem;
                        margin-bottom: 0;
                        padding-bottom: 0;
                        margin: 0;
                        text-align: left;
                        &::after {
                            display: none;
                        }
                    }
                    p {
                        margin: .5rem 0 0 0;
                        text-align: left;
                        color: $white;
                    }
                }
            }
        }
    }
    .pagination {
        position: absolute;
        height: 50px;
        left: 2rem;
        right: 2rem;
        bottom: -1rem;
        z-index: 1;
        .swiper-button-next {
            height: 22px;
        }
        .swiper-button-next:after {
            background-image: url('/files/themes/rula-brw/images/icons/pfeilBlau.svg');
            background-repeat: no-repeat;
            color: transparent;
            height: 19px!important;
            width: 19px!important;
            transform: rotateZ(-90deg);
        }
        .swiper-button-prev {
            height: 22px;
        }
        .swiper-button-prev:after {
            background-image: url('/files/themes/rula-brw/images/icons/pfeilBlau.svg');
            background-repeat: no-repeat;
            height: 19px!important;
            width: 19px!important;
            color: transparent;
            transform: rotateZ(90deg);
        }
    }
}

@mixin rsce_hochSlider_tablet {
    .ce_rsce_hochSlider {
        .container.text {
            .container-inner {
                .headline {
                    width: 50%;
                }
            }
        }
    }
}

@mixin rsce_hochSlider_desktop_s {
    .ce_rsce_hochSlider {
        .container.text {
            padding: $paddingDesktop;
            margin-bottom: 2rem;
            .container-inner {
                .headline {
                    width: 50%;
                    min-width: 250px;
                }
                p {
                    width: 50%;
                    min-width: 250px;
                }
            }
        }

        .swiper-container {
            margin-left: 25%;
            .swiper-wrapper {
                .swiper-slide {
                    .text {
                        h2 {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
        .pagination {
            position: absolute;
            height: 50px;
            width: 100px;
            right: 29%;
            transform: translateX(50%);
            left: unset;
            top: 0;
        }
    }
}

@mixin rsce_hochSlider_desktop {
    .ce_rsce_hochSlider {
        .swiper-container {
            
            .swiper-wrapper {
                .swiper-slide {
                    .text {
                        h2 {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }
} 