.ce_rsce_ansprechpartner {
    padding: $paddingMobile;
    max-width: $maxWidth;
    margin: 0 auto;
    .container {

        .container-inner {
            h2 {
                width: 50%;
                min-width: 250px;
            }
            &.ansprechpartner {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 2rem;
                .ansprechpartner {
                    flex: calc(25% - 2rem);
                    width: calc(25% - 2rem);
                    min-width: 200px;
                    .name {
                        font-family: 'Mont';
                        font-weight: 700;
                        color: $primary;
                        text-transform: uppercase;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
        }
        
        
    }
}

@mixin rsce_ansprechpartner_tablet {
    .ce_rsce_ansprechpartner {
        padding: $paddingDesktop;
        .container {
            .container-inner {
                &.ansprechpartner {
                    .ansprechpartner {
                        max-width: calc(25% - 2rem);
                    }
                }
            }
        }
    }
}