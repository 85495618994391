.ce_rsce_infoBox {
    max-width: $maxWidth;
    padding: $paddingMobile;
    margin: 0 auto;
    .container {
        padding: 2rem 2rem 2rem 2rem;
        border: 2px solid $primary;
        position: relative;
        .icon {
            position: absolute;
            top: -5px;
            left: 0;
            width: 50px;
            height: 100px;
            background-color: $white;
            transform: translateX(-50%);
            padding: 2rem 0;
            img {
                max-width: 50px;
                max-height: 50px;
            }
        }
        .container-inner {
            h2 {
                font-size: 1.5rem;
                padding-bottom: 0;
                &::after {
                    display: none;
                }
                margin: 0;
            }
            p {
                margin-top: .5rem;
            }
            a {
                color: $black;
                font-weight: bold;
            }
        }
    }
}

@mixin rsce_infoBox_tablet {
    .ce_rsce_infoBox {
        padding: $paddingDesktop;
        .container {
            padding: 2rem 2rem 2rem 4rem;
            .container-inner {
                h2 {
                    font-size: 2.5rem;
                }
            }
        }
    }
}