.ce_rsce_produktFilter {
    background-color: $gray;
    border-top: 2px solid #D0CFCA;
    border-bottom: 2px solid #D0CFCA;
    .container {
        padding: $paddingMobile;
        max-width: $maxWidth;
        margin: 0 auto;
        h2 {
            &::after {
                display: none;
            }
        }
        .container-inner {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 2rem;
            h2 {
                font-size: 1.75rem;
                margin-bottom: 0rem;
                line-height: 1;
                margin-top: 0;
                padding-bottom: 0;
            }
            &:first-of-type {
                display: block;
            }
            &:last-of-type {
                margin-top: 2rem;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: end;
                p {
                    color: $primary;
                    font-weight: bold;
                    margin: 0;
                }
                .filterReset {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
            .filter {
                height: fit-content;
                flex: calc(20% - 2rem);
                min-width: 150px;
                border: 1px solid $primary;
                position: relative;
                &.active {
                    p:after {
                        transform: rotateZ(-180deg);
                    }
                }
                .counter {
                    display: none;
                    position: absolute;
                    top: 0;
                    right:2rem;
                    color: $white;
                    background-color: $primary;
                    padding: .5rem;
                }
                &.active {
                    .filter-inner {
                        display: flex;
                    }
                }
                p {
                    margin: 0;
                    color: $primary;
                    font-weight: bold;
                    position: relative;
                    padding: .5rem;
                    &:hover {
                        cursor: pointer;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        right: .5rem;
                        top: 40%;
                        width: 20px;
                        height: 10px;
                        background-image: url('/files/themes/rula-brw/images/icons/pfeilBlau.svg');
                        background-repeat: no-repeat;
                        transition: transform .5s;
                    }
                }
                .filter-inner {
                    display:none;
                    flex-direction: column;
                    max-height: 250px;
                    overflow-y: scroll;
                    position: relative;
                    input {
                        padding: .2rem;
                        width: 95%;
                        margin: 0 auto;
                    }
                    span {
                        border-bottom: .5px solid gray;
                        margin: 0 0 0rem .5rem;
                        padding: .5rem 0;
                        position: relative;
                        &:first-of-type {
                            padding-top: .5rem;
                            border-top: .5px solid gray;
                        }
                        &:last-of-type {
                            border-bottom: unset;
                        }
                        &:hover {
                            cursor: pointer;
                        }
                        &.active {
                            font-weight: bold;
                            &::after {
                                content: "";
                                position: absolute;
                                right: 0;
                                top: 50%;
                                width: 20px;
                                height: 20px;
                                background-image: url('/files/themes/rula-brw/images/icons/check.svg');
                                transform: translate3d(-50%,-50%,0);
                                background-repeat: no-repeat;
                            }
                        }
                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 20px;
                            height: 20px;
                            background-repeat: no-repeat;
                            background-size: cover;
                        }
                        &.premiumkalt {
                            padding-left: 35px;
                            &:before {
                                background-image: url('/files/themes/rula-brw/images/icons/premiumKalt.svg');
                            }
                        }
                        &.premiumheiss{
                            padding-left: 35px;
                            &:before {
                                background-image: url('/files/themes/rula-brw/images/icons/premiumHeiß.svg');
                            }
                        }
                        &.standardkalt{
                            padding-left: 35px;
                            &:before {
                                background-image: url('/files/themes/rula-brw/images/icons/standarrtKalt.svg');
                            }
                        }
                        &.standardheiss{
                            padding-left: 35px;
                            &:before {
                                background-image: url('/files/themes/rula-brw/images/icons/standartHeiß.svg');
                            }
                        }
                        &.ringtread{
                            padding-left: 35px;
                            &:before {
                                background-image: url('/files/themes/rula-brw/images/icons/ringtread.svg');
                                height: 25px;
                            }
                        }
                        &.budget{
                            padding-left: 35px;
                            &:before {
                                background-image: url('/files/themes/rula-brw/images/icons/bugdet.svg');
                            }
                        }
                    }
                
                }
                .button {
                    width: 100%;
                    display: block;
                    margin: 0;
                    padding: .75rem 1rem;
                    text-align: center;
                    background-color: $primary;
                    color: $white;
                    display: none;
                } 
            }
        }
    }
}


@mixin rsce_produktFilter_tablet {
    .ce_rsce_produktFilter {
        .container {
            padding: $paddingDesktop;
            .container-inner {
                
            }
        }
    }
}