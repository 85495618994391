.ce_rsce_sideIcons {
    position: fixed;
    right: 0;
    top: 40%;
    transform: translateY(-50%);
    width: 50px;
    background-color: $black;
    z-index: 100;
    .container {
        .container-inner {
            display: flex;
            flex-direction: column;
            .icon {
                border-bottom: 1px solid gray;
                padding: .05rem;
                &:last-of-type {
                    border: unset;
                }
                a {
                    scroll-behavior:smooth;
                    img {
                        transition: transform .5s;
                    }
                    &:hover {
                        img {
                            transform: scale(1.15);
                            filter: brightness(0) saturate(100%) invert(86%) sepia(100%) saturate(521%) hue-rotate(345deg) brightness(101%) contrast(98%);
                        }
                    }
                }
            }
        }
    }
}