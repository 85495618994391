.ce_rsce_footer {
    background: linear-gradient(180deg, #044283 0, #044280 31%, #001c4f 100%);
    .container {
        padding: $paddingMobile;
        max-width: $maxWidth;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .container-inner {
            ul {
                margin-left: 0;
                padding-left: 0;
                margin: 0 0 1rem 0;
            }
            li {
                list-style: none;
            }
            a,p {
                color: $white;
                margin: 0 0 1rem 0;
            }
            strong {
                color: $white;
                text-transform: uppercase;
            }
            &:first-of-type {
                flex: calc(20%);
                min-width: 250px;
               
                a {
                    text-transform: uppercase;
                    line-height: 2rem;
                    font-family: 'Mont';
                    font-weight: 700;
                    font-size: .9rem;
                }
            }
            &:nth-child(2){
                flex: calc(50%);
                min-width: 250px;
                
                a {
                    text-transform: uppercase;
                    line-height: 2rem;
                    font-family: 'Mont';
                    font-weight: 700;
                    font-size: .9rem;
                }
            }
            &:last-of-type {
                flex: calc(30%);
                min-width: 250px;
                position: relative;
                p {
                    line-height: 2rem;
                    font-size: .9rem;
                    strong {
                        font-family: 'Mont';
                        font-weight: 700;
                    }
                }
                .icon {
                    position: absolute;
                    bottom: -2rem;
                    right: 0;
                }
            }
        }
    }

    .containercopyright {
        background-color: $white;
        .container {
            max-width: $maxWidth;
            margin: 0 auto;
            padding: $paddingMobile;
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .container-inner {
            flex: calc(33% - 1rem)!important;
            min-width: 250px;
            color: $black;
            display: flex;
            align-items: center;
            &:nth-child(1){
                justify-content: center;
            }
            &:nth-child(2){
                justify-content: center;
                p {
                    font-size: .75rem;
                }
            }
            &:nth-child(3){
                justify-content: center;
                gap: 1rem;
            }
            .mod_customnav {
                a, strong  {
                    font-family: 'ClearSans';
                    font-weight: 300;
                    color: $black;
                    text-transform: initial;
                    font-size: .9rem;
                    margin: 0;
                }
                strong {
                    font-weight: bold;
                }
            }
            p {
                color: $black;
                font-size: .9rem;
                margin: 0;
            }
            ul {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                gap: 1rem;
                margin: 0;
            }
        }
    }
}



@mixin rsce_footer_tablet {
    .ce_rsce_footer {
        .container {
            padding: $paddingDesktop;
            .container-inner {
                &:nth-child(1){
                    a {
                        //font-size: unset;
                    }
                }
                &:nth-child(2){
                    a {
                        //font-size: unset;
                    }
                }
                &:last-of-type {
                    .icon {
                        position: absolute;
                        bottom: -3rem;
                        right: 0;
                    }
                }
            }
        }
        .containercopyright {
            .container {
                padding: 2rem 2rem;
                gap: 0;
                .container-inner {
                    flex: 33%!important;
                    &:nth-child(1){
                        justify-content: left;
                    }
                    &:nth-child(3){
                        justify-content: end;
                    }
                }
            }
        }
    }
}