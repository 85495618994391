body {
	margin: 0; // 1
	font-family: $theme-font-family, $font-family-base;
	font-weight: $fwRegular;
	line-height: $mainLineHeight;
	color: $fontColor;
	text-align: left;
	background-color: transparent; // 2
	-webkit-text-size-adjust: 100%; // 3
	-webkit-tap-highlight-color: rgba($black, 0); // 4
	letter-spacing: 0.01em;
	word-spacing: 0.01em;
	hyphens: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a{
    color: $link-color;
    text-decoration: $link-decoration;
    &:hover{
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
}

strong {
	color: $primary;
}

h1, h2, h3, h4, h5, h6 {
	color: $headings-color;
	hyphens: none;
}


p {
	color: $black;
}

@mixin heading_mobile {
	p {
		font-size: .9rem;
	}
	h2 {
		font-size: 1.4rem;
		font-weight: 700;
		line-height: 2.06rem;
		color: $primary;
		font-family: 'Mont';
		position: relative;
		padding-bottom: 1.5rem;
		margin-bottom: 1.5rem;
		
		&:after {
            content: "";
            position: absolute;
            bottom: 0rem;
            left: 0%;
            width: 117px;
            height: 4px;
            background-color: $secondary;
            transform: translate3d(0%,50%,0);
        }
	}
	h3 {
		font-size: 1rem;
		font-weight: 300;
		line-height: 2rem;
		font-family: 'Mont';
	}
}

@mixin heading_tablet {
	p {
		font-size: 1rem;
	}
	h2 {
		font-size: 2.75rem;
		line-height: 3.06rem;
		margin-bottom: 3rem;
		color: $primary;
		font-family: 'Mont';
	}
	h3 {
		font-size: 1rem;
		font-weight: 300;
		line-height: 2rem;
		font-family: 'Mont';
	}
}

@mixin heading_desktop {
	h2 {
		font-size: 3.75rem;
		line-height: 4.06rem;
		color: $primary;
		font-family: 'Mont';
	}
	h3 {
		font-size: 1.5rem;
		font-weight: 300;
		line-height: 2.5rem;
		font-family: 'Mont';
	}
}



p,
table,
ul,
ol,
blockquote,
figure {
	@include scaleText($size: 1, $linesAbove: 2, $linesBelow: 2);
}
small {
	@include scaleText($size: msInt(-1), $linesAbove: 1, $linesBelow: 1);
}
/*! Fluid Type */
@include fluid-type($minFontSize, $maxFontSize, $minFontWidth, $maxFontWidth);