.ce_rsce_newsletter {
    .container {
        padding: $paddingMobile;
        max-width: 900px;
        margin: 0 auto;
        .container-inner {
            margin-bottom: 3rem;
            h2 {
                text-align: center;
                margin-bottom: 0;
                &::after {
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
            p {
                text-align: center;
            }
            .ce_form {
                .widget {
                    p, legend, span {
                        text-align: left;
                        color: $primary;
                    }
                    &.mandatory:after {
                        color: $secondary;
                    }
                }
            }
        }
    }
}


@mixin rsce_newsletter_tablet {
    .ce_rsce_newsletter {
        .container {
            padding: $paddingDesktop;
            .container-inner {

            }
        }
    }
}