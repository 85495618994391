.content-text {
    padding: $paddingMobile;
    text-align: center;
    max-width: 1100px;
    margin: 0 auto;

    h2 {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 117px;
            height: 4px;
            background-color: $secondary;
            transform: translate3d(-50%,50%,0);
        }
    }

}


@mixin text_tablet {
    .content-text {
        margin-top: 2rem;
        padding: $paddingDesktop
    }
}