.ce_rsce_cta {
    background-color: $secondary;
    display: flex;
    align-items: center;
    .container {
        padding: $paddingMobile;
        max-width: $maxWidth;
        margin: 0 auto;
        width: 100%;
        .container-inner {
            a {
                font-weight: bold;
                color: $black;
                text-decoration: underline;
            }
            h2 {
                font-family: 'Mont';
                font-weight: 700;
                line-height: 2rem;
                margin: 0;
                padding: 0;
                &:after {
                    display: none;
                }
            }
            p {
                margin-bottom: 0;
            }
        }
    }
}

@mixin rsce_cta_tablet {
    .ce_rsce_cta {
        min-height: 320px;
        .container {
            padding: $paddingDesktop;
            .container-inner {
                h2 {
                    font-size: 1.5rem;
                }
            }
        }
    }
}