.ce_rsce_trennerGruen {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(141, 202, 71);
        mix-blend-mode: multiply;
    }
    .paralax-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
        overflow: hidden;
    }
    .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: $paddingMobile;
        max-width: $maxWidth;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        .container-inner {
            flex: 50%;
            min-width: 250px;
            .boldHeading {
                margin-bottom: 0;
                padding-bottom: 0;
                font-weight: 700;
                &::after {
                    display: none;
                }
            }
            h2 {
                margin-top: 0;
                padding-top: 0;
                text-transform: uppercase;
                color: $white;
                font-weight: 200;
                &::after {
                    background-color: $white;
                }
            }
        }
    }
}


@mixin rsce_trennerGruen_tablet {
    .ce_rsce_trennerGruen {
        .container {
            min-height: 750px;
            padding: 5rem 2rem;
            .container-inner {
                &:first-of-type {
                    flex: 40%;
                }
                &:nth-child(2){
                    flex: 60%;
                }
            }
        }
    }
}