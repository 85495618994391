.ce_rsce_slideshow {
    height: 94vh;
    .container {
        position: relative;
        border-bottom: 8px solid $secondary;
        .swiper-container {
            overflow: clip;
            max-height: 94vh;
            .swiper-slide {
                max-height: 100vh;
                height: 94vh;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,.35);
                }
                img {
                    max-height: 94vh;
                }
                .swiper-slide-inner {
                    position: absolute;
                    padding: $paddingMobile;
                    width: 100%;
                    top: 15%;
                    z-index: 1;
                    .text {
                        max-width: $maxWidth;
                        margin: 0 auto;
                        text-align: left;
                        h2 {
                            font-family: 'Mont';
                            font-weight: 700;
                            color: $white;
                            margin-bottom: 1rem;
                            &::after {
                                display: none;
                            }
                        }
                        p {
                            color: $white;
                            margin: 0;
                            a {
                                color: $white;
                                position: relative;
                                &::after {
                                    content: "→";
                                    position: absolute;
                                    right: -1.5rem;
                                    bottom: -.5rem;
                                }
                            }
                        }
                        
                    }
                }
                .arrowDown {
                    position: absolute;
                    left: 50%;
                    bottom: 2rem;
                    transform: translateX(-50%);
                    z-index: 1;
                    &:hover {
                        cursor: pointer;
                    }
                    img {
                        width: 2rem;
                        height: 1rem;
                        animation: arrow 2s infinite;
                    }
                }
            }
        }
        .swiper-button-next {
            right: var(--swiper-navigation-sides-offset, 2rem)!important;
            width: 30px;
        }
        .swiper-button-next:after {
            background-image: url('/files/themes/rula-brw/images/icons/headerArrow.svg');
            color: transparent;
            transform: rotateZ(-180deg);
            width: 100%;
        }
        .swiper-button-prev {
            left: var(--swiper-navigation-sides-offset, 2rem)!important;
            width: 30px;
        }
        .swiper-button-prev:after {
            background-image: url('/files/themes/rula-brw/images/icons/headerArrow.svg');
            color: transparent;
            width: 100%;
        }
    }
}


@mixin rsce_slideshow_tablet {
    .ce_rsce_slideshow {
        .container {
            .swiper-container {
                .swiper-slide {
                    .swiper-slide-inner {
                        top: 35%;
                        h2 {
                            font-size: 5.62rem;
                            line-height: 5rem;
                        }
                        p, a {
                            font-size: 1.35rem;
                            line-height: 1.6rem;
                        }
                    }
                }
            }
        }
    }
}


@keyframes arrow{
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
   
    50% {
        opacity: 1;
        transform: translateY(0px);
    }
   
    100% {
        opacity: 0;
        transform: translateY(+20px);
    }
}