.ce_rsce_zeitstrahl {
    max-width: $maxWidth;
    padding: $paddingMobile;
    margin: 0 auto;
    .headline, .text {
        text-align: center;
        hyphens: auto;
        &::after {
            display: none;
        }
    }
    .zeitstrahl {
        margin-top: 7rem;
        .item {
            display: flex;
            flex-direction: column;
            position: relative;
            .zahl {
                position: absolute;
                top: 0;
                left: 2rem;
                transform: translate3d(-50%,-50%,0);
                background-color: $white;
                color: $primary;
                z-index: 1;
                font-size: 1.5rem;
            }
            .item-inner {
                flex: 50%;
                min-width: 250px;
                margin-left: 4rem;
                h2 {
                    font-size: 1.1rem;
                    line-height: 1.75rem;
                    padding-bottom: 0;
                    margin-bottom: .5rem;
                    hyphens: auto;
                    &::after {
                        display: none;
                    }
                }
                img {
                    padding-top: 4rem;
                }
                p {
                    position: relative;
                    padding-bottom: 2rem;
                    margin-top: .5rem;
                    
                }
            }
            &::after {
                content: "";
                position: absolute;
                left: 2rem;
                top: 0;
                width: 2px;
                height: 100%;
                background-color: $primary;
            }
            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 1rem;
                width: 70%;
                height: 2px;
                background-color: $primary;
                
            }
        }
        
    }
    
}


@mixin rsce_zeitstrahl_tablet_l {
    .ce_rsce_zeitstrahl {
        padding: $paddingDesktop;
        .zeitstrahl {
            .item {
                display: flex;
                flex-direction: row;
                .zahl {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate3d(-50%,-50%,0);
                    background-color: $white;
                    z-index: 1;
                    font-size: 2.5rem;
                }
                .item-inner {
                    margin-left: 0rem;
                    img {
                        padding-top: 0;
                    }
                    h2 {
                        font-size: 2rem;
                        line-height: unset;
                    }
                }
                &::after {
                    left: 50%;
                }
                
            }
            // Bild links
            .item:nth-child(2n + 1){
                .item-inner {
                    padding: 4rem 0 7rem 0;
                }
                img {
                    padding-right: 7rem;
                }
                h2 , p {
                    padding-left: 7rem;
                }
                p {
                    position: relative;
                    padding-bottom: 2rem;
                }
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    left: unset;
                    width: 40%;
                    height: 2px;
                    background-color: $primary;
                    
                }
            }
            // Bild rechts
            .item:nth-child(2n + 2){
                .item-inner {
                    padding: 7rem 0 7rem 0;
                }
                .item-inner:nth-child(2) {
                    order: 2;
                
                }  
                img {
                    padding-left: 7rem;
                }
                h2 , p {
                    padding-right: 7rem;
                }
                p {
                    position: relative;
                    padding-bottom: 2rem;
                   
                }
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: unset;
                    width: 40%;
                    height: 2px;
                    background-color: $primary;
                    
                }
            }
        }
    }
}


@mixin rsce_zeitstrahl_desktop {
    .ce_rsce_zeitstrahl {
        .zeitstrahl {
            .item {
                .item-inner {
                    h2 {
                        font-size: 2.65rem;
                        line-height: 3.2rem;
                    }
                }
            }
        }
    }
}