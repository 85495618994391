.ce_rsce_downloads{
	max-width: 77rem;
    margin: 0 auto;
	padding: 2rem;
	.inside{
		margin-inline: auto;
		
		.headline-container{
			text-align: center;
			
			h2::after {
    			left: 50%;
    			transform: translate3d(-50%, -50%, 0);
			}
		}
		.downloads-container{
			margin-block-start: 1.8rem;
			background: none;
			a::after{
				display: none;
			}
			.kategorie {
				color: $black;
				font-weight: bold;
                font-size: 1.5rem;
				&:nth-child(1n+2){
					margin-top: 4rem;
				}
			}
			.download{
				border-block-start: .5px solid #A1A3A9;
				padding-block: 1.44rem 1.25rem;
				.left{
					display: flex;
					flex-flow: row nowrap;
					gap: 1.611rem;
					.icon{
						background: none;
					}
					.icon-pdf{
						color: rgba(0, 0, 0, .1);
						display: block;
						height: 1.8rem;
						&::before{
							font-size: 1.8rem;
							line-height: 1;
						}
					}
					.title{
						color: black;
						font-size: 1.08rem;
						font-weight: $fwBold;
						letter-spacing: .035em;
						line-height: 1.28;
						text-transform: uppercase;
						display: flex;
						align-items: center;
						margin-block-start: .1rem;
						background: none;
					}
				}
				.right{
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					margin-block-start: 1rem;
					.last-modified{
						color: scale-color($color: $fontColor, $alpha: -60%);
						font-size: .72rem;
						line-height: 1.26;
						margin-inline-end: 2.33rem;
						text-transform: uppercase;
						white-space: nowrap;
					}
					.download-link{
						display: flex;
						flex-flow: row nowrap;
						align-items: center;
						white-space: nowrap;
						background: none;
						.icon-download{
							display: block;
							height: 1.8rem;
							&::before{
								color: var(--primary-color);
								font-size: 1.8rem;
								line-height: 1;
							}
						}
						.text{
							color: rgba(0, 0, 0, .2);
							font-size: .72rem;
							font-weight: $fwBold;
							letter-spacing: .035em;
							margin-inline-end: 1.36rem;
							text-transform: uppercase;
							background: none;
						}
					}
				}
				&:last-of-type{
					border-block-end: .5px solid #A1A3A9;
				}
			}
		}
		.button-container{
			margin-block-start: 2.944rem;
			text-align: center;
		}
	}
}

@mixin rsce_downloads_tablet{
	.ce_rsce_downloads{
		padding: 3rem 2rem;
		.inside{
			.downloads-container{
				margin-block-start: 3.8rem;
				.download{
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 3rem;
					.left{
						margin-inline-start: .777rem;
					}
					.right{
						margin-block-start: 0;
					}
				}
			}
		}
	}
}
