.ce_rsce_bildboxen {
    max-width: $maxWidth;
    margin: 0 auto;
    padding: $paddingMobile;
    .container {
        .container-inner {
            .headline {
                width: 40%;
                min-width: 250px;
            }
        }
        .container-inner.list {
           display: flex;
           flex-direction: row;
           flex-wrap: wrap;
           gap: 3rem;
           .item {
                flex: calc(50% - 2rem);
                width: calc(50% - 2rem);
                min-width: 300px;
                max-height: 300px;
                position: relative;
                overflow: hidden;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,0.2);
                }
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition: opacity .5s;
                    background-color: rgba(5,71,148,0.8);
                    mix-blend-mode: multiply;
                    z-index: 1;
                }
                &:hover {
                    &::before {
                        opacity: 1;
                    }
                    .icon, .text {
                        opacity: 1;
                        z-index: 1;
                    }
                }
                .icon {
                    display: block;
                    opacity: 0;
                    transition: opacity .5s;
                    position: absolute;
                    left: 50%;
                    top: 10%;
                    transform: translateX(-50%);
                    width: 50px;
                    height: 100px;
                    img {
                        aspect-ratio: unset;
                        object-fit: cover;
                    }
                }
                .text {
                    display: block;
                    opacity: 0;
                    transition: opacity .5s;
                    position: absolute;
                    left: 0;
                    bottom: 5%;
                    width: 100%;
                    height:50px; 
                }
                .text p {
                    width: 90%;
                    font-weight: 400;
                    font-size: .7rem;
                    line-height: 1rem;
                }
                img {
                    width: 100%;
                    object-fit: cover;
                    aspect-ratio: 16/9;
                    transition: transform .5s;
                }
                p {
                    position: absolute;
                    font-family: "Mont";
                    font-weight: 700;
                    left: 50%;
                    top: 50%;
                    color: $white;
                    transform: translate3d(-50%, -50%,0);
                    text-align: center;
                    font-size: 1.55rem;
                    line-height: 2.1rem;
                    z-index: 1;
                    margin: 0;
                }
            }
        }
    }
}


@mixin rsce_bildboxen_tablet {
    .ce_rsce_bildboxen {
        padding: $paddingDesktop;
        .container {
            .container-inner.list {
                p {
                    font-size: 1.85rem;
                }
                .item {
                    .icon {
                        width: 100px;
                    }
                    .text {
                      height: 100px;
                    }
                    .text p {
                        width: 80%;
                        font-size: 1rem;
                        line-height: 1.5rem;
                    }
                }
            }
        }
    }
}