.ce_rsce_produkte {
    padding: $paddingMobile;
    max-width: $maxWidth;
    margin: 0 auto;
    .container {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        .container-inner {
            flex: 100%;
            .heading {
                font-weight: 700;
                font-size: 1.75rem;
                padding-bottom: 0;
                &::after {
                    display: none;
                }
            }
           
            .icons {
                display: flex;
                flex-direction: row;
                gap: .5rem;
                flex-wrap: wrap;
                .icon {
                    display: flex;
                    align-items: center;
                    font-size: .85rem;
                    gap: .2rem;
                    margin-bottom: 1rem;
                    color: #939393;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
    .container-produkte {
        .container-inner {
            .mod_navigation {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 2rem;
                .produkt {
                    flex: calc(50% - 2rem);
                    width: calc(50% - 2rem);
                  
                    text-decoration: none;
                    &:has(.zusatzinfo:hover){
                        z-index: 1;
                    }
                    .name p {
                        background-color: $white;
                        margin: 0;
                        padding-top: .5rem;
                        z-index: 1;
                        position: relative;
                    }
                    .produkt-inner {
                        position: relative;
                        img {
                            transition: transform .5s;
                            width: 100%;
                            object-fit: cover;
                        }
                        .merkmale {
                            position: absolute;
                            right: .5rem;
                            bottom: .5rem;
                            width: 20px;
                            height: fit-content;
                            display: flex;
                            flex-direction: column;
                            gap: .5rem;
                            .icon {
                                width: 20px;
                                height: 20px;
                                background-repeat: no-repeat;
                                background-size: cover;
                                &.premiumkalt {
                                    background-image: url('/files/themes/rula-brw/images/icons/premiumKalt.svg');
                                }
                                &.premiumheiss {
                                    background-image: url('/files/themes/rula-brw/images/icons/premiumHeiß.svg');
                                }
                                &.standartkalt {
                                    background-image: url('/files/themes/rula-brw/images/icons/standarrtKalt.svg');
                                }
                                &.standartheiss {
                                    background-image: url('/files/themes/rula-brw/images/icons/standartHeiß.svg');
                                }
                                &.Ringtread {
                                    background-image: url('/files/themes/rula-brw/images/icons/ringtread.svg');
                                    height: 25px;
                                }
                                &.budget {
                                    background-image: url('/files/themes/rula-brw/images/icons/bugdet.svg');
                                }
                            }
                        }
                        .ganzjahresprofil {
                            position: absolute;
                            left: .5rem;
                            bottom: .5rem;
                            width: 27px;
                            height: 27px;
                            .icon{
                                display: block;
                                width: 100%;
                                height: 100%;
                                background-image: url('/files/themes/rula-brw/images/icons/ganzjahresprofil.svg');
                                background-repeat: no-repeat;
                                background-size: cover;
                            }
                        }
                        .zusatzinfo {
                            position: absolute;
                            top: .5rem;
                            right: .5rem;
                            z-index: 1;
                            &:hover, &.active {
                                .zusatzContent {
                                    display: block;
                                    opacity: 1;
                                    height: fit-content;
                                    width: max-content;
                                }
                            }
                            .info {
                                cursor: pointer;
                                width: 27px;
                            }
                            .zusatzContent {
                                opacity: 0;
                                display: none;
                                transition: all .25s;
                                position: absolute;
                                left: 3rem;
                                top: 0;
                                width: 0px;
                                height: 0px;
                                background-color: rgba(5,71,148,.85);
                                min-width: 250px;
                                padding: .5rem 1rem .5rem 1rem;
                                li {
                                    list-style: none;
                                    position: relative;
                                    margin-bottom: 1rem;
                                    color: $white;
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        top: 5%;
                                        left: -1.9rem;
                                        width: 25px;
                                        height: 25px;
                                        background-image: url('/files/themes/rula-brw/images/icons/checkbox-icon.svg');
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



@mixin rsce_produkte_tablet {
    .ce_rsce_produkte {
        padding: $paddingDesktop;
        .container {
            flex-direction: column;
            .container-inner {
                .heading {
                    margin: 0;
                }
                .icons {
                    justify-content: start;
                    .icon {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .container-produkte {
            .container-inner {
                .mod_navigation {
                    gap: 2rem;
                    .produkt {
                        flex: calc(16.66% - 2rem);
                        width: calc(16.66% - 2rem);
                        max-width: calc(16.66% - 2rem);
                    }
                }
            }
        }
    }
}