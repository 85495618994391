.mod_search {
    padding: $paddingMobile;
    max-width: $maxWidth;
    margin: 0 auto;
    .formbody {
        display: flex;
        gap: 1rem;
        input {
            padding: 1rem 1rem 1rem 1rem;
        }
        button {
            display: inline-block;
            padding: 1rem 1rem 1rem 1rem;
            border: 1px solid $primary;
            color: $primary;
            background-color: transparent;
            transition: all .5s;
            width: fit-content;
            cursor: pointer;
            &:hover {
                cursor: pointer;
                background-color: $primary;
                color: $white;
                text-decoration: none;
            }
        }
    }
}


@mixin mod_search_tablet {
    .mod_search {
        padding: $paddingDesktop;
    }
}