.ce_rsce_navigation {
    background-color: $white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    height: 90px;
    z-index: 100;
    padding: 0 2rem;
    box-shadow: 0px 3px 6px #00000029;
   
    .container {
        padding: .3rem 0rem .2rem 0rem;
        max-width: $maxWidth;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        transition: padding .5s;
        .container-inner {
            &:first-of-type {
                order: 1;
                
            }
            &:nth-child(2){
                order: 3;
                margin-top: 1.3rem;
                
            }
            &:last-of-type {
                order: 2;
                margin-top: 1.3rem;
                
            }
            &.search {
                display: flex;
                align-items: center;
                gap: 2rem;
                .sprache {
                    select {
                        border: none;
                        outline: none;
                        color: $primary;
                        background-color: transparent;
                    }
                }
                .search {
                    display: none;
                    cursor: pointer;
                    position: relative;
                    &:hover {
                        .searchform {
                            height: 100px;
                            opacity: 1;
                        }
                    }
                    &.active {
                        .searchform {
                            height: 100px;
                            opacity: 1;
                        }
                    }
                    .searchform {
                        height: 0px;
                        opacity: 0;
                        position: absolute;
                        padding-top: 1.7rem;
                        left: -200px;
                        transition: all .5s;
                        form {
                            display: flex;
                            align-items: center;
                            gap: .5rem;
                            border: 4px solid $primary;
                            background-color: $white;
                            input {
                                min-width: 200px;
                                width: 100%;
                                outline: none;
                                text-align: center;
                                border: none;
                            }
                            .searchbutton {
                                width: 30px;
                                background-color: $primary;
                                img {
                                    padding: .1rem;
                                    filter: brightness(0) saturate(100%) invert(93%) sepia(57%) saturate(0%) hue-rotate(206deg) brightness(106%) contrast(107%);
                                }
                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
            .menuButton {
                position: relative;
                width: 40px;
                height: 20px;
                &:hover {
                    cursor: pointer;
                }
                .line {
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    top: 50%;
                    background-color: $primary;
                    transition: transform .5s;
                    &:first-of-type {
                        top: 0;
                    }
                    &:last-of-type {
                        top: 100%;
                    }
                }
                &.active {
                    .line {
                        top: 50%;
                        transform: rotateZ(-45deg);
                        &:first-of-type {
                            top: 50%;
                            transform: rotateZ(45deg);
                        }
                        &:last-of-type {
                            display: none;
                        }
                    }

                }
            }
            .mobile {
                display: block;
                position: absolute;
                right: -150%;
                top: 90px;
                width: 100vw;
                height: 100vh;
                background-color: $primary;
                transition: right .5s;
                z-index: 10000;
                padding: 2rem;
                &.active {
                    right: 0;
                }
                .mod_navigation {
                    ul {
                        margin: 0;
                        padding: 0;
                        li {
                            list-style: none;
                            margin-bottom: 1rem;
                            position: relative;
                            a {
                                color: $white;
                            }
                            strong, .trail {
                                color: $white;
                                font-weight: bold;
                            }
                            &.submenu:hover {
                                cursor: pointer;
                            }
                            &.submenu::after {
                                content: "";
                                position: absolute;
                                top: 13px;
                                right: 0;
                                background-image: url('/files/themes/rula-brw/images/icons/arrowDownYellow.svg');
                                background-repeat: no-repeat;
                                background-size: contain;
                                width: 25px;
                                height: 13px;
                                transform: rotateZ(-90deg);
                                transition: transform .5s;
                            }
                            &.submenu.open:after {
                                transform: rotateZ(0);
                            }
                        }
                    }
                    .level_1 {
                        li.submenu.open {
                            .level_2 {
                                display: block;
                                height: 100%;
                            }
                        }
                    }
                    .level_2{
                        display: none;
                        height: 0%;
                        margin-top: 1rem;
                        margin-bottom: 3rem;
                        transition: height .5s;
                    }
                }
            }
            .desktop {
                display: none;
            }
            
        }
    }
}

@mixin rsce_navigation_tablet {
    .ce_rsce_navigation {
        height: unset;
        &.scrolled {
            .container {
                padding: .2rem 2rem;
            }
        }
        .container {
            padding: 1rem 2rem;
            gap: 1rem;
            .container-inner {
                &:first-of-type {
                    order: 1;
                }
                &:nth-child(2){
                    order: 2;
                }
                &:last-of-type {
                    order: 3;
                }
                &.search {
                    .search {
                        display: block;
                    }
                }
                .menuButton {
                    display: none;
                }
                .mobile {
                    display: none;
                }
                .desktop {
                    display: block;
                    .mod_navigation {
                        ul {
                            margin: 0;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                            gap: 1.5rem;
                            position: relative;
                            li {
                                list-style: none;
                                position: relative;
                                a,strong {
                                    color: $black;
                                    transition: color .5s;
                                    font-size: 1rem;
                                    position: relative;
                                    z-index: 1;
                                    &:hover {
                                        color: $primary;
                                        text-decoration: none;
                                    }
                                }
                                strong, .trail {
                                    color: $primary;
                                    font-weight: bold;
                                }
                            }
                        }
                        li {
                            z-index: 1;
                        }
                        li.submenu {
                            a {
                                z-index: 1;
                                position: relative;
                            }
                            &:hover {
                                .level_2 {
                                    display: flex;
                                }
                            }
                        }
                        
                        .level_2 {
                            display: none;
                            position: absolute;
                            top: 0rem;
                            left: 0;
                            gap: .5rem;
                            min-width: 250px;
                            padding: 3rem 2rem 2rem 1rem;
                            flex-direction: column;
                            background-color: rgba(255,255,255,0.9);
                            margin-top: 1.5rem;
                            li {
                                padding: 0 0 .5rem;
                                margin: 0;
                                border-bottom: 1px solid $primary;
                                &:last-of-type {
                                    border-bottom: unset;
                                    padding-bottom: unset;
                                }
                            }
                        }
                        
                    }
                }
            }
        }
    }
}