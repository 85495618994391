.ce_rsce_popup {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    width: max-content;
    height: fit-content;
    max-width: 250px;
    height: 250px;
    z-index: 10000;
    .container {
        .container-inner {
            .close {
                position: absolute;
                right: 0;
                bottom: 0rem;
                width: 30px;
                height: 30px;
                background-color: $black;
                border-radius: 50%;
                transition: .5s all;
                border: 3px solid transparent;
                z-index: 100;
                &.active {
                    transform: rotateZ(-45deg);
                    border: 3px solid $white;
                }
                &:hover {
                    cursor: pointer;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 20%;
                    height: 1px;
                    width: 15px;
                    background-color: $white;
                    transition: .5s all;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 20%;
                    left: 50%;
                    height: 15px;
                    width: 1px;
                    background-color: $white;
                    transition: .5s all;
                }
            }
            .content {
                width: 0;
                height: 0;
                max-height: 100%;
                opacity: 0;
                position: absolute;
                display: none;
                right: 0rem;
                background-color: $black;
                padding: 2rem 1rem 1rem 1.5rem;
                color: $white;
                transition: .5s all;
                overflow: hidden;
                &.active {
                    display: block;
                    width: max-content;
                    height: fit-content;
                    opacity: 1;
                    max-width: 80vw;
                }
                a {
                    color: $white;
                    text-decoration: underline;
                }
                .heading {
                    font-family: 'Mont';
                    font-weight: 700;
                    font-size: 2rem;
                    line-height: 2.37rem;
                    max-width: 50%;
                    margin: 0;
                    color: $white;
                }
                p {
                    color: $white;
                }
                img {
                    display: none;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 150px;
                    height: 100px;
                    object-fit: cover;
                }
            }
        }
    }
}



@mixin rsce_popup_tablet {
    .ce_rsce_popup {
        max-width: 400px;
        bottom: 2.5rem;
        .container {
            .container-inner{
                .close {
                    bottom: -2rem;
                    width: 71px;
                    height: 71px;
                    &:before {
                        width: 35px;
                        top: 50%;
                        left: 50%;
                        transform: translate3d(-50%,-50%,0);
                    }
                    &::after {
                        height: 35px;
                        top: 50%;
                        left: 50%;
                        transform: translate3d(-50%,-50%,0);
                    }
                }
                .content {
                    right: 2rem;
                    bottom: 0rem;
                    &.active {
                        max-width: 400px;
                    }
                   
                    img {
                        display: block;
                    }
                }
            }
        }
    }
}
