.ce_rsce_formular {
    .container {
        padding: $paddingMobile;
        max-width: $maxWidth;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
        h2 {
            text-align: center;
            width: 100%;
            color: $black;
            text-transform: uppercase;
            &::after {
                background-color: $black;
                left: 50%;
                transform: translate3d(-50%,-50%,0);
            }
        }
        .container-inner {
            flex: calc(50% - 2rem);
            width: calc(50% - 2rem);
            min-width: 250px;
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
            .content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .blue {
                    color: $primary;
                    margin: 1rem;
                }
                img {
                    max-width: 80px;
                }
                a {
                    font-weight: bold;
                    color: $black;
                    font-family: 'Mont';
                    font-size: 1.8rem;
                    margin: 0;
                }
            }
        }
    }
}

.ce_form {
    .formbody {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
        .widget {
            flex: calc(50% - 2rem);
            width: calc(50% - 2rem);
            min-width: 250px;
            input, select , textarea{
                width: 100%;
                height: 100%;
                padding: 1rem 1rem 1rem .5rem;
                color: $primary;
                background-color: $gray;
                border: 1px solid transparent;
                &::placeholder {
                    color: $primary;
                }
            }
            button {
                padding: 1rem 1rem 1rem 1rem;
                border: 1px solid $primary;
                color: $primary;
                background-color: transparent;
                transition: all .5s;
                &:hover {
                    cursor: pointer;
                    background-color: $primary;
                    color: $white;
                    text-decoration: none;
                }
            }
        }
        .widget-explanation  {
            p {
                margin: -1rem 0;
                color: $primary;
            }
        }
        .widget-textarea {
            flex: 100%;
            width: 100%;
            textarea {
                width: 100%;
                height: 100%;
                max-width: 100%;
                min-width: 100%;
                max-height: 300px;
                min-height: 100%;
            }
        }
        .widget.mandatory {
            position: relative;
            &::after {
                content: "*";
                position: absolute;
                top: 5px;
                right: 5px;
                color: $secondary;
            }
        }
        .widget-checkbox {
            flex: 100%;
            .checkbox_container {
                input {
                    width: 30%;
                }
                input[type=radio], input[type=checkbox] {
                    display: none;
                }
                .style {
                    display: inline-block;
                    width: 20px;
                    min-width: 20px;
                    height: 20px;
                    background-color: $white;
                    cursor: pointer;
                    position: relative;
                    vertical-align: middle;
                    border: 1px solid $primary;
                }
            
                .style:after {
                    content: '';
                    position: absolute;
                    display: none;
                    left: 6px;
                    top: 4px;
                    width: 5px;
                    height: 10px;
                    border: solid #000;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            
                input[type=radio]:checked + label:after, input[type=checkbox]:checked + label:after {
                    display: block;
                }

                span {
                    display: flex;
                    align-items: baseline;
                    max-width: unset;
                    width: 100%;
                    gap: 1rem;

                    p {
                        display: block;
                        margin: 0;
                    }
                    a {
                        color: $black;
                        text-decoration: underline;
                        &:hover {
                            color: $black;
                        }
                    }
                }

            }
        }
    }
}

@mixin rsce_formular_tablet {
    .ce_rsce_formular {
        .container {
            .container-inner {
                min-width: 450px;
                img {
                    max-width: 300px;
                    margin: 0 auto;
                }
            }
        }
    }
}

@mixin rsce_formular_desktop_s {
    .ce_rsce_formular {
        .container {
            padding: $paddingDesktop;
            h2 {
                margin: 0 30% 4rem 30%;
            }
            .container-inner {
                img {
                    max-width: 100%;
                }
                .content {
                    width: fit-content;
                    max-width: max-content;
                    margin-top: 5rem;
                    a {
                        font-size: 2.8rem;
                    }
                }
            }
        }
    }
}