.ce_rsce_bildContent {
   
    .container {
        padding: $paddingMobile;
        max-width: $maxWidth;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
        .container-inner {
            flex: calc(50% - 2rem);
            width: calc(50% - 2rem);
            min-width: 300px;
            img {
                max-width: 400px;
                width: 100%;
            }
        }
    }
}

@mixin rsce_bildContent_tablet {
    .ce_rsce_bildContent {
        .container {
            padding: $paddingDesktop;
            .container-inner {

            }
        }
    }
}