
@font-face {
    font-family: 'ClearSans';
    src: url('/files/themes/rula-brw/fonts/ClearSans-Light.ttf'), format('truetype'),
        url('/files/themes/rula-brw/fonts/ClearSans-Light.woff'), format('woff'),
        url('/files/themes/rula-brw/fonts/ClearSans-Light.woff2'), format('woff2');
    font-weight: 300;    
}
@font-face {
    font-family: 'ClearSans';
    src: url('/files/themes/rula-brw/fonts/ClearSans-Bold.ttf'), format('truetype'),
        url('/files/themes/rula-brw/fonts/ClearSans-Bold.woff'), format('woff'),
        url('/files/themes/rula-brw/fonts/ClearSans-Bold.woff2'), format('woff2');
    font-weight: 700;    
}

@font-face {
    font-family: 'Mont';
    src: url('/files/themes/rula-brw/fonts/Mont-ExtraLight.ttf'), format('truetype'),
        url('/files/themes/rula-brw/fonts/Mont-ExtraLight.woff'), format('woff'),
        url('/files/themes/rula-brw/fonts/Mont-ExtraLgiht.woff2'), format('woff2');
    font-weight: 200;    
}

@font-face {
    font-family: 'Mont';
    src: url('/files/themes/rula-brw/fonts/Mont-Book.ttf'), format('truetype'),
        url('/files/themes/rula-brw/fonts/Mont-Book.woff'), format('woff'),
        url('/files/themes/rula-brw/fonts/Mont-Book.woff2'), format('woff2');
    font-weight: 500;    
}

@font-face {
    font-family: 'Mont';
    src: url('/files/themes/rula-brw/fonts/Mont-Heavy.ttf'), format('truetype'),
        url('/files/themes/rula-brw/fonts/Mont-Heavy.woff'), format('woff'),
        url('/files/themes/rula-brw/fonts/Mont-Heavy.woff2'), format('woff2');
    font-weight: 700;    
}