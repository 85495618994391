.ce_rsce_stellenAnzeige {
    .container {
        padding: $paddingMobile;
        max-width: $maxWidth;
        margin: 0 auto;
        .container-inner {
            h2 {
                text-align: center;
                &::after {
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
			
            .kategorie {
                color: $primary;
                font-size: 1.5rem;
            }
            .stellenanzeige {
                padding: 1.5rem 0rem;
                position: relative;
                cursor: pointer;
                &:first-of-type {
                    border-top: 1px solid #A1A3A9;
                }
                border-bottom: 1px solid #A1A3A9;
                &.active {
                    .close {
                       background-image: url('/files/themes/rula-brw/images/icons/minus.svg');
                       background-size: cover;
                    }
                }
                .close {
                    position: absolute;
                    right: 1rem;
                    top: 20px;
                    width: 40px;
                    height: 40px;
                    background-image: url('/files/themes/rula-brw/images/icons/plus.svg');
                    background-size: cover;
                    transition: all .5s;
                }
                .heading {
                    max-width: 70%;
                }
                .heading p {
                    font-size: 1.08rem;
                    font-weight: bold;
                    margin: 0;
                }
                .stellenanzeige-inner {
                    height: 0;
                    opacity: 0;
                    pointer-events: none;
                    .stellenInfo {
                        span {
                            color: $primary;
                            font-weight: bold;
                        }
                        p {
                            margin-top: .5rem;
                        }
                    }
                    &.active {
                        height: fit-content;
                        opacity: 1;
                        margin-top: 2rem;
                    }
                }
            }
        }
    } 
}


@mixin rsce_stellenAnzeige_tablet {
    .ce_rsce_stellenAnzeige {
        .container {
            .container-inner {
                .kategorie { 
                    font-size: 2rem;
                }
                .stellenanzeige {
                    .heading {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}