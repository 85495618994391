.ce_rsce_trenner {
    padding: 2rem 0rem;
    position: relative;
    min-height: 700px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(134, 255, 12);
        mix-blend-mode: multiply;
    }
    .container {
        max-width: $maxWidth;
        padding: $paddingMobile;
        margin: 0 auto;
        z-index: 1;
        position: relative;
        .container-inner {
            h2 {
                color: $white;
                min-width: 250px;
                &::after {
                    background-color: $white;
                }
            }
            p {
                color: $white;
                min-width: 250px;
            }
        }
    }
    .icon {
        position: absolute;
        right: 1rem;
        bottom: 0;
        z-index: 1;
        img {
            max-width: 125px;
        }
    }
}


@mixin rsce_trenner_tablet {
    .ce_rsce_trenner {
        padding: 3rem 2rem;
        margin-bottom: 6rem;
        .container {
            padding: unset;
            .container-inner {
                h2 {
                    width: 60%;
                }
                p {
                    width: 32%;
                }
            }
        }
        .icon {
            position: absolute;
            right: 10rem;
            bottom: -6rem;
            z-index: 1;
            img {
                max-width: 250px;
            }
        }
    }
}