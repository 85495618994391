/*! --Layout-- */
body {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 100vh;
	scroll-behavior: smooth;
	&:has(.ce_rsce_headerUnterseite){
		.content-text {
			margin-top: 0rem;
		}
	}
}
footer {
	margin-top: auto;
}
img {
	display: block;
	max-width: 100%;
	height: auto;
	width: auto;
}

ul {
	padding-left: 1rem;
}

.button {
	display: inline-block;
	padding: .75rem 1rem .75rem 1rem;
	border: 1px solid $primary;
	color: $primary;
	background-color: transparent;
	transition: all .5s;
	width: fit-content;
	cursor: pointer;
	&:hover {
		cursor: pointer;
		background-color: $primary;
		color: $white;
		text-decoration: none;
	}
}

.button.white {
	display: inline-block;
	padding: .75rem 1rem .75rem 1rem;
	border: 1px solid $white;
	background-color: transparent;
	color: $white;
	text-decoration: none;
	transition: all .5s;
	width: fit-content;
	cursor: pointer;
	&:hover {
		cursor: pointer;
		background-color: $white;
		color: $primary;
	}
}


.widget-radio {
	flex: 100%!important;
	.radio_container {
		display: flex;
		flex-direction: row;
		gap: 2rem;
		border: none;
		span {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: .5rem;
		}
	}
	input {
		width: 30%;
	}
	input[type=radio], input[type=checkbox] {
		display: none;
	}
	.style {
		display: inline-block;
		width: 20px;
		min-width: 20px;
		height: 20px;
		background-color: $white;
		cursor: pointer;
		position: relative;
		vertical-align: middle;
		border: 1px solid $primary;
	}

	.style:after {
		content: '';
		position: absolute;
		display: none;
		left: 6px;
		top: 4px;
		width: 5px;
		height: 10px;
		border: solid $white;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
	}

	input[type=radio]:checked + label {
		background-color: $primary;
	}
	input[type=radio]:checked + label:after, input[type=checkbox]:checked + label:after {
		display: block;
	}
}


.mod_article {
	margin-top: 90px;
}

@mixin article_tablet {
	.mod_article:has(.ce_rsce_headerUnterseite), .mod_article:has(.ce_rsce_slideshow){
		margin-top: 0px;
	}
	.mod_article {
		margin-top: 150px;
	}
}