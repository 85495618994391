.ce_rsce_lineContent {
    .container {
       padding: $paddingMobile;
        .container-inner {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 1rem;
            margin-bottom: 2rem;
            align-items: center;
            &:last-of-type {
                margin-bottom: 0rem;
            }
            img {
                aspect-ratio: 16/9;
                margin-bottom: 1rem;
            }
            .zahl {
                font-family: 'Mont';
                font-size: 1.75rem;
                margin: 0;
                font-weight: 700;
                color: $primary;
            }
            h2 {
                margin-top: 0;
                font-weight: 200;
                margin-bottom: 1rem;
                padding-bottom: 1rem;
            }
            ul {
                margin: 0;
                padding-left: 1rem;
            }
           
            // Bild rechts
            &:nth-child(even){
                position: relative;
               
                .image {
                    order: 1;
                    flex: calc(50% - 2rem);
                    min-width: 250px;
                }
                .text {
                    order: 2;
                    flex: calc(50% - 2rem);
                    min-width: 250px;
                }
            }

            // Bild links
            &:nth-child(odd){
                position: relative;
               
                .image {
                    order: 1;
                    flex: calc(50% - 2rem);
                    min-width: 250px;
                }
                .text {
                    order: 2;
                    flex: calc(50% - 2rem);
                    min-width: 250px;
                }
            }
           
        }
    }
}

@mixin rsce_lineContent_tablet {
    .ce_rsce_lineContent {
        .container {
            .container-inner {
                flex-direction: row;
                margin-bottom: 3rem;
                img {
                    height: 100%;
                    object-fit: cover;
                }
                .zahl {
                    font-size: 2.75rem;

                    margin-bottom: .5rem;
                }
                &:nth-child(even){
                    .image, .text {
                        min-width: 500px;
                    }
                }
                &:nth-child(odd){
                    .image, .text {
                        min-width: 500px;
                    }
                } 
            }
        }
    }
}


@mixin rsce_lineContent_desktop_s {
    .ce_rsce_lineContent {
        .container {
            padding: unset;
            .container-inner {
                gap: 2rem;
                flex-direction: row;
                padding-top: 3rem;
                padding-bottom: 5rem;
                margin-bottom: unset;
                h2 {
                    margin-bottom: 2rem;
                    hyphens: auto;
                }
                // Bild rechts
                &:nth-child(even){
                    padding-bottom: 4rem;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0px;
                        left: 50%;
                        transform: translateX(-50%);
                        height: 3px;
                        width: 50%;
                        background-color: $primary;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: 0px;
                        left: 2rem;
                        height: calc(100% + 3px);
                        width: 40%;
                        border-top-left-radius: 50%;
                        border-bottom-left-radius: 50%;
                        border-left: 3px solid $primary;
                        border-top: 3px solid $primary;
                        border-bottom: 3px solid $primary;
                    }
                    .zahl {
                        font-size: 3.75rem;
                        margin-bottom: 1.5rem;
                    }
                    .image {
                        order: 2;
                        flex: calc(50% - 2rem);
                        min-width: 250px;
                    }
                    .text {
                        order: 1;
                        flex: calc(50% - 2rem);
                        padding-left: 15%;
                        padding-right: 6%;
                        min-width: 250px;
                    }
                }

                // Bild links
                &:nth-child(odd){
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0px;
                        left: 50%;
                        transform: translateX(-50%);
                        height: 3px;
                        width: 50%;
                        background-color: $primary;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: 0px;
                        right: 2rem;
                        height: calc(100% + 3px);
                        width: 40%;
                        border-top-right-radius: 50%;
                        border-bottom-right-radius: 50%;
                        border-right: 3px solid $primary;
                        border-top: 3px solid $primary;
                        border-bottom: 3px solid $primary;
                    }
                    .zahl {
                        font-size: 3.75rem;
                        margin-bottom: 1.5rem;
                    }
                    .image {
                        order: 1;
                        flex: calc(50% - 2rem);
                        min-width: 250px;
                    }
                    .text {
                        order: 2;
                        flex: calc(50% - 2rem);
                        padding-right: 15%;
                        padding-left: 1%;
                        min-width: 250px;
                    }
                }
                &:first-of-type {
                    &::before {
                        content: unset;
                    }
                    &::after {
                        
                    }
                }
                &:last-of-type {
                    &::after {
                        
                    }
                }
            }
        }
    }
}