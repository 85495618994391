.ce_rsce_headerUnterseite {
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0.3) 100%);
        opacity: .3;
    }
    .container {
        position: absolute;
        top: 50%;
        left: 0%;
        width: 100%;
        transform: translateY(-50%);
        &.desktop, &.mobile {
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
        }
        &.mobile {
            background-position: right;
        }
        &.desktop {
            display: none;
        }
        .container-inner {
            padding: $paddingMobile;
            max-width: $maxWidth;
            margin: 0 auto;
            .headline {
                margin: 0;
                color: $white;
                z-index: 1;
                font-size: 2rem;
                padding-bottom: 0;
                max-width: 80vw;
                hyphens: auto;
                &::after {
                    display: none;
                }
            }
        }
    }
}

@mixin rsce_headerUnterseite_tablet {
    .ce_rsce_headerUnterseite {
        margin-top: 5rem;
        .container {
            &.desktop {
                display: block;
            }
            &.mobile {
                display: none;
            }
           .container-inner {
            padding: $paddingDesktop;
                .headline {
                    font-size: 4rem;
                    line-height: 5rem;
                }
           }
        }
    }
}

@mixin rsce_headerUnterseite_desktop_s {
    .ce_rsce_headerUnterseite {
        .container {
            padding-left: 2rem;
           .container-inner {
            padding: unset;
            
            .headline {
                font-size: 5.6rem;
                line-height: 5rem;
            }
           }
        }
    }
}