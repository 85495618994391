.ce_rsce_vorteile {
   
    .container {
        max-width: $maxWidth;
        margin: 0 auto;
        padding: $paddingMobile;
        .container-inner {
            .headline {
                min-width: 250px;
            }
        }
        .container-inner.list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 2rem;
            img {
                max-width: 40px;
            }
            h3 {
                font-weight: 600;
                margin-bottom: 1rem;
            }
           
            .preview-text {
                p {
                    margin-top: 0;
                }
                &.active {
                    position: absolute;
                    opacity: 0;
                    height: 0;
                }
                strong {
                    color: $black;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .full-text {
                opacity: 0;
                height: 0;
                transition: all .5s;
                strong {
                    color: $black;
                    &:hover {
                        cursor: pointer;
                    }
                }
                &.active {
                    height: 100%;
                    opacity: 1;
                    transition: all .0s;
                }
            }
            &.two .item {
                flex: calc(50% - 2rem);
                width: calc(50% - 2rem);
                min-width: 250px;
            }
            &.three .item {
                flex: calc(33% - 2rem);
                width: calc(33% - 2rem);
                min-width: 250px;
            }
            &.four .item {
                flex: calc(25% - 2rem);
                width: calc(25% - 2rem);
                min-width: 250px;
            }
        }
    }
}


@mixin rsce_vorteile_tablet {
    .ce_rsce_vorteile {
        .container {
            padding: $paddingDesktop;
            .container-inner {
                .headline {
                    width: 50%;
                }
            }
            .container-inner.list {
                h3 {
                    font-size: 1.5rem;
                }
                &.two .item {
                   max-width: calc(50% - 2rem);
                }
                &.three .item {
                    max-width: calc(33% - 2rem);
                }
                &.four .item {
                    max-width: calc(25% - 2rem);
                }
            }
        }
    }
}