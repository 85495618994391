.ce_rsce_textBild {
    padding: $paddingMobile;
    
    .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
        .container-inner {
            flex: calc(50% - 2rem);
            width: calc(50% - 2rem);
            min-width: 250px;
            h2 {
                margin-top: 0;
            }
            img {
                width: 100%;
            }
        }
    }
}

@mixin rsce_textBild_tablet {
    .ce_rsce_textBild {
        .container {
            .container-inner {
                min-width: 500px;
            }
        }
    }


}

@mixin rsce_textBild_desktop_s {
    .ce_rsce_textBild {
        padding: 6rem 0rem;
        .container {
            gap: 2rem;
            .container-inner{
                flex: calc(50% - 2rem);
                width: calc(50% - 2rem);
                min-width: 450px;
                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
            &.left {
                .container-inner {
                    &:last-of-type {
                        padding-right: 10%;
                        padding-left: 2rem;
                    }
                }
            }
           &.right {
            .container-inner {
                &:first-of-type {
                    order: 2;
                }
                &:last-of-type {
                    order: 1;
                    padding-left: 15%;
                    padding-right: 1%;
                    padding-right: 2rem;
                }
            }
           }
        }
    }
}