/*! --Breakpoints-- */
@include mq($mobile_s) {
	@include heading_mobile;
}

@include mq($mobile) {
	
}

@include mq($mobile_l) {
	
}

@include mq($tablet_s) {

}

@include mq($tablet) {
	@include article_tablet;
	@include rsce_slideshow_tablet;
    @include heading_tablet;
	@include text_tablet;
	@include rsce_bildboxen_tablet;
	@include rsce_vorteile_tablet;
	@include rsce_textBild_tablet;
	@include rsce_trenner_tablet;
	@include rsce_trennerZaehler_tablet;
	@include rsce_bildContent_tablet;
	@include rsce_ansprechpartner_tablet;
	@include rsce_cta_tablet;
	@include rsce_kundenstimmen_tablet;
	@include rsce_marken_tablet;
	@include rsce_sortiment_tablet;
	@include rsce_produktDetail_tablet;
	@include rsce_footer_tablet;
	@include rsce_headerUnterseite_tablet;
	@include rsce_hochSlider_tablet;
	@include rsce_trennerGruen_tablet;
	@include rsce_produktFilter_tablet;
	@include rsce_filterIcons_tablet;
	@include rsce_formular_tablet;
	@include rsce_slider_tablet;
	@include rsce_produkte_tablet;
	@include rsce_infoBox_tablet;
	@include mod_search_tablet;
	@include rsce_icons_tablet;
	@include rsce_iconCounter_tablet;
	@include rsce_popup_tablet;
	@include rsce_lineContent_tablet;
	@include rsce_contentMittig_tabelt;
	@include rsce_newsletter_tablet;
	@include rsce_downloads_tablet;
	@include rsce_stellenAnzeige_tablet;
}

@include mq($tablet_l) {
	@include rsce_zeitstrahl_tablet_l;
	@include rsce_history-tablet_l;
}

@include mq($desktop_s) {
	@include rsce_headerUnterseite_desktop_s;
	@include rsce_navigation_tablet;
	@include rsce_textBild_desktop_s;
	@include rsce_trennerZaehler_desktop_s;
	@include rsce_kundenstimmen_desktop;
	@include rsce_lineContent_desktop_s;
	@include rsce_formular_desktop_s;
	@include rsce_hochSlider_desktop_s;
	@include rsce_slider_destkop_s;
}

@include mq($desktop) {
	@include heading_desktop;
	@include rsce_hochSlider_desktop;
	@include rsce_zeitstrahl_desktop;
}

@include mq($desktop_l) {
	
}
