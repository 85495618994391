.ce_rsce_produktDetail {
    .container {
        padding: $paddingMobile;
        max-width: $maxWidth;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 2rem;
        .container-innericons {
            flex: 100%;
            .icons {
                display: flex;
                flex-direction: row;
                gap: .5rem;
                margin-bottom: 2rem;
                img {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                }
            }
        }
        .container-inner {
            flex: calc(65% - 2rem);
            order: 2;
            min-width: 250px;
            &:last-of-type {
                flex: calc(35% - 2rem);
                order: 1;
            }
            ul {
                margin: 0;
                padding-left: 1rem;
            }

            .daten {
                display: flex;
                flex-direction: row;
                gap: 1rem;
                margin-top: 2rem;
                .heading {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 0rem;
                    color: $primary;
                    font-weight: bold;
                    flex: 40%;
                    p {
                        margin: .5rem 0;
                        color: $primary;
                    }
                }
                .daten-content {
                    display: flex;
                    flex-direction: row;
                    
                    gap: 0rem;
                    flex: 60%;
                    overflow-x: scroll;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    .daten-inner {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        min-width: 200px;
                        p {
                            margin: .5rem 0;
                        }
                    }
                  
                    
                }
            }
            .datenblatt {
                margin-top: 4rem;
                .datendownload {
                    position: relative;
                    padding-left: 3rem;
                    padding-right: 2rem;
                    font-size: 1.5rem;
                    text-transform: uppercase;
                    font-weight: bold;
                    &:hover {
                        text-decoration: none;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 32px;
                        height: 39px;
                        transform: translateY(-50%);
                        background-image: url('/files/themes/rula-brw/images/icons/pdf.svg');
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 50%;
                        width: 24px;
                        height: 22px;
                        transform: translateY(-50%);
                        background-image: url('/files/themes/rula-brw/images/icons/download.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
            }
           
        }
    }
}



@mixin rsce_produktDetail_tablet {
    .ce_rsce_produktDetail {
        .container {
            padding: $paddingDesktop;
            gap: 0;
            .container-inner {
                order: 1;
                flex: calc(65% - 4rem);
                order: 2;
                min-width: 250px;
               
                &:last-of-type {
                    margin-left: 4rem;
                    flex: calc(35% - 4rem);
                    order: 2;
                }
                .daten {
                    flex-direction: column;
                    gap: unset;
                    .heading {
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 2rem;
                        p {
                            flex: calc(25% - 2rem);
                            border-bottom: unset;
                        }
                    }
                    .daten-content {
                        flex-wrap: wrap;
                        flex-direction: column;
                        border-top: .5px solid $black;
                        .daten-inner {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 2rem;
                            border-bottom: .5px solid $black;
                            min-width: unset;
                            p {
                                flex: calc(25% - 2rem);
                                border-bottom: unset;
                            }
                        }
                       
                    }
                }
            }
        }
    }
}