/*! ----NETPROFIT---- */
@import "@netprofit/scss-mixins/mixins.scss"; // Import mixins
// Overwrite Global Variables here

@import "variables.scss"; // Import Variables


/*! --Plugins & Libraries-- */
@import "@netprofit/typography/main.scss"; // Import Typography Lib after settings


/*
---
name: Gridlex
category: SCSS Libraries
---
Wir benutzen zum layouten das auf flexbox basierende Grid-System Gridlex.

**Beispiel:**
```sample:/styleguide/assets/gridlex-demo.html
```
Nutzung und Möglichkeiten lassen sich in der [Gridlex Dokumentation](http://gridlex.devlint.fr/) nachlesen.

[Dokumentation zu den SCSS Variablen](http://gridlex.devlint.fr/#sass-documentation)

Falls kein Grid-System benötigt wird, einfach auskommentieren.
*/
/*
$gl-colCount: 12;
$gl-gutter: 1rem;
$gl-gutter-vertical: 1rem;
$gl-mq-width: 'max-width';
$gl-mq-list: (
	lg: $desktop,
	md: $tablet, 
	sm: $tablet_s, 
	xs: $mobile 
);
@import "gridlex/src/gridlex.scss";
*/

/*! --Fonts-- */
/*
---
name: Mono Icons
category: SCSS Libraries
---
Wir benutzen [Mono Icons](https://icons.mono.company/).

**Nutzung:** Dem Element, in dem das Icon angezeigt werden soll, die Klasse `.mi-<icon-name-here>` geben

Falls Kein Icon Font benötigt wird, einfach auskommentieren.

```icons:/styleguide/assets/icons.html
```
*/
@import "_lib/mono-icons.scss";

@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/1_plugins/aos.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/1_plugins/swiper.scss";


/*! ---STANDARD--- */
@import "_lib/modern-normalize.scss"; // CSS Reset
@import "@netprofit/scss-contao-base/contao"; // Basic CSS for Contao

/*! ---CUSTOM--- */
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/2_base/0_font.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/2_base/1_typography.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/2_base/2_layout.scss";
 // Base of the Theme

/*! --Elements-- */
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/mod_search.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_ansprechpartner.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_bildboxen.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_bildContent.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_contentMittig.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_cta.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_downloads.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_filterIcons.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_footer.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_formular.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_headerUnterseite.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_history.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_hochSlider.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_iconCounter.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_icons.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_infoBox.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_kundenstimmen.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_lineContent.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_marken.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_navigation.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_newsletter.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_popup.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_produktDetail.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_produkte.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_produktFilter.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_sideIcons.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_slider.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_slideshow.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_sortiment.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_stellenAnzeige.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_textBild.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_trenner.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_trennerGruen.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_trennerZaehler.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_vorteile.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/rsce_zeitstrahl.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/3_elements/text.scss";
 // Component Styling

@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/4_last/0_breakpoints.scss";
@import "/Users/johannesblochl/Desktop/DEV/rula-brw.de/contao/files/themes/rula-brw/src/scss/4_last/1_print.scss";
 // Media Queries
