.ce_rsce_slider {
    padding: $paddingMobile;
    background-color: $gray;
    position: relative;
    .swiper-container {
        background-color: $gray;
        max-width: $maxWidth;
        margin: 0 auto;
        overflow: clip;
        padding-bottom: 4rem;
        .swiper-wrapper {
            .swiper-slide {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                background-color: $gray;
                .slide-inner {
                    display: flex;
                    flex-direction: column;
                    justify-content: left;
                    position: relative;
                    h2, p, a {
                        text-align: left;
                    }
                    h2 {
                        margin: 0;
                    }
                }
            }
        }
    }
    .pagination {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        transform: translateX(-50%);
        
        width: 115px;
        height: 40px;
        .swiper-button-next {
            right: var(--swiper-navigation-sides-offset, 0rem)!important;
          
        }
        .swiper-button-next:after {
            background-image: url('/files/themes/rula-brw/images/icons/arrowDownBlue.svg');
            color: transparent;
            transform: rotateZ(270deg);
            width: 100%;
            background-repeat: no-repeat;
        }
        .swiper-button-prev {
            left: var(--swiper-navigation-sides-offset, 0rem)!important;
            
        }
        .swiper-button-prev:after {
            background-image: url('/files/themes/rula-brw/images/icons/arrowDownBlue.svg');
            color: transparent;
            width: 100%;
            background-repeat: no-repeat;
            transform: rotateZ(90deg);
        }
    }
}

@mixin rsce_slider_tablet {
    .ce_rsce_slider {
        .swiper-container {
            .swiper-wrapper {
                .swiper-slide {
                    h2 {
                        margin: initial;
                    }
                    flex-direction: row;
                    .slide-inner {

                    }
                }
            }
        }
    }
}

@mixin rsce_slider_destkop_s {
    .ce_rsce_slider {
        .swiper-container {
            padding: $paddingDesktop;
            .swiper-wrapper {
                .swiper-slide {
                    h2 {
                        margin: initial;
                    }
                    flex-direction: row;
                    .slide-inner {

                    }
                }
            }
        }
        .pagination {
            position: absolute;
            bottom: 5rem;
            left: 50%;
            transform: translateX(-13rem);
            z-index: 1;
        }
    }
}