.ce_rsce_kundenstimmen {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 700px;
    margin: 0 auto;
    .container {
       position: relative;
       max-width: $maxWidth;
       margin: 0 auto;
        .swiper-container {
            overflow: clip;
            max-width: $maxWidth;
            margin: 0 auto;
            padding: 3rem;
            min-height: 650px;
            display: flex;
            align-items: center;
            .swiper-wrapper {
                .swiper-slide {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    background-color: transparent!important;
                    img {
                        height: 50px;
                        object-fit: cover;
                        width: 50px;
                    }
                    .text {
                        color: $white;
                        p {
                            color: $white;
                        }
                    }
                    .name {
                        color: $white;
                        margin: 0;
                    }
                    span {
                        font-size: 3.75rem;
                        color: $secondary;
                    }
                }

            }
        }

        .swiper-button-next {
            right: var(--swiper-navigation-sides-offset, 1rem)!important;
            height: 22px!important;
        }
        .swiper-button-next:after {
            background-image: url('/files/themes/rula-brw/images/icons/kundenstimmenArrow.svg');
            background-repeat: no-repeat;
            color: transparent;
            height: 22px!important;
            transform: rotateZ(-180deg);
        }
        .swiper-button-prev {
            left: var(--swiper-navigation-sides-offset, 1rem)!important;
            height: 22px!important;
        }
        .swiper-button-prev:after {
            background-image: url('/files/themes/rula-brw/images/icons/kundenstimmenArrow.svg');
            background-repeat: no-repeat;
            height: 22px!important;
            color: transparent;
        }
    }
}

@mixin rsce_kundenstimmen_tablet {
    .ce_rsce_kundenstimmen {
        .container {
            .swiper-container {
                .swiper-slide {
                    .text {
                        p {
                            font-size: 2rem;
                            line-height: 2.5rem;
                        }
                    }
                }
            }
        }
    }
}

@mixin rsce_kundenstimmen_desktop {
    .ce_rsce_kundenstimmen {
        .container {
            .swiper-container {
                padding: 5rem;
                .swiper-slide {
                    
                    .text {
                        p {
                            font-size: 2.8rem;
                            line-height: 3.2rem;
                        }
                    }
                }
            }
        }
    }
}