.ce_rsce_iconCounter {
    max-width: $maxWidth;
    margin: 0 auto;
    padding: 2rem 2rem 5rem 2rem;
    .container {
        .container-inner {
            &:first-of-type {
                text-align: center;
                p {
                    margin: 0;
                }
                
                .headline {
                    margin-bottom: 2rem;
                    &::after {
                        left: 50%;
                        transform: translate3d(-50%,-50%,0);
                    }
                }
            }
        }
        .container-inner.icons {
            margin-top: 4rem;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1rem;
            position: relative;
            .icon {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0rem;
                flex: calc(25% - 0rem);
                width: calc(25% - 0rem);
                min-width: 220px;
                margin-bottom: 2rem;
                img {
                    margin-bottom: 1rem;
                }
                p {
                    margin: 0;
                }
                p:first-of-type {
                    //margin-bottom: -2rem;
                }
               
                .zahl {
                    font-family: 'Mont';
                    font-size: 2rem;
                    font-weight: 700;
                    //margin-top: -2rem;
                    margin-bottom: .5rem;
                    color: $primary;
                }

            }
            .zusatz {
                position: absolute;
                right: 0;
                bottom: -2rem;
                margin: 0;
                font-size: 16px;
            }
        }
    }
}


@mixin rsce_iconCounter_tablet {
    .ce_rsce_iconCounter {
        padding: 2rem 2rem 5rem 2rem;
        .container {
            .container-inner {
                &:first-of-type {
                    max-width: 60%;
                    margin: 0 auto;
                }
            }
            .container-inner.icons {
                .icon {
                    gap: 1rem;
                    flex: calc(25% - 1rem);
                    width: calc(25% - 1rem);
                    margin-bottom: 0rem;
                    p:first-of-type {
                        margin-bottom: unset;
                    }
                    .zahl {
                        margin-top: .5rem;
                        font-size: 4.68rem;
                    }
                }
                .zusatz {
                    position: absolute;
                    right: 0;
                    bottom: -4rem;
                    margin: 0;
                    font-size: 16px;
                }
            }
        }
    }
}