.ce_rsce_marken {
    .container {
        padding: $paddingMobile;
        max-width: $maxWidth;
        margin: 0 auto;
        .container-inner {
           
            h2 {
                max-width: 50%;
            }

            &.marken {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 2rem;
                .marke {
                    flex: calc(20% - 2rem);
                    width: calc(20% - 2rem);
                    max-width: calc(20% - 2rem);
                    min-width: 120px;
                   
                    img {
                        transition: transform .5s;
                        &:hover {
                            transform: scale(1.04);
                        }
                    }
                }
            }
        }
    }
}



@mixin rsce_marken_tablet {
    .ce_rsce_marken {
        .container {
            padding: $paddingDesktop;
            .container-inner {
                &.marken {
                    .marke {
                        max-width: calc(20% - 2rem);
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}