.ce_rsce_history{
	background-color: #F3F3F3;
	overflow: clip;
	.inside{
        max-width: 77rem;
        margin: 0 auto;
		margin-inline: auto;
        padding: $paddingMobile;
		.left{
			padding-block: 4.02rem 3.63rem;
			position: relative;
			z-index: 4;
			.headline-container{
				h2{
					line-height: 1.14;
                    text-align: left;
				}
			}
			.text-container{
				margin-block-start: 1.5rem;
				*{
					text-align: left;
				}
			}
			.button-container{
				margin-block-start: 2.1rem;
                display: flex;
                .button {
                    border: unset;
                    color: $black;
                    border-bottom: 1px solid black;
                    &:hover {
                        background-color: transparent;
                    }
                }
			}
            .swiper-slide {
                background-color: transparent;
            }
			.swiper-pagination{
				position: initial;
				top: initial;
				left: initial;
				right: initial;
				bottom: initial;
				margin-block-start: 1.16rem;
				text-align: left;
				white-space: nowrap;
				display: flex;
				flex-flow: row nowrap;
				gap: 1.944rem;
				scrollbar-width: none;
				overflow: auto;
				cursor: grab;
				&:active{
					cursor: grabbing !important;
					span{
						cursor: grabbing;
					}
				}
			}
		}
		.right{
			display: flex;
			flex-flow: column;
			justify-content: flex-start;
			padding-block-end: 4.02rem;
			position: relative;
			img{
				height: 100%;
				object-fit: cover;
				width: 100%;
			}
			.swiper-slide{
				transition: opacity ease .2s;
				&:not(.swiper-slide-active){
					opacity: .16;
				}
			}
			.swiper-navigation{
				position: relative;
				.swiper-button-prev,
				.swiper-button-next{
					height: 2.972rem;
					opacity: 1 !important;
					top: initial;
					bottom: 0;
					width: 1.888rem;
					
					&::after{
                        width: 20px;
                        height: 20px;
						background-image: url('/files/themes/rula-brw/images/icons/arrowDownBlue.svg');
                        background-size: contain;
                        content: "";
                        background-repeat: no-repeat;
                        transform: rotateZ(90deg);    
					}
				}
				.swiper-button-prev{
					left: -2rem;
				}
				.swiper-button-next{
					left: 100%;
					&::after{
						transform: rotate(-90deg);
					}
				}
			}
		}
	}
}

@mixin rsce_history-tablet_l{
	.ce_rsce_history{
		overflow: clip;
		.inside{
            padding: $paddingMobile;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			.left{
				overflow: clip;
				width: 29.85%;
			}
			.right{
				padding-inline-start: 1.888rem;
				width: 66.5%;
				&::before{
					content: '';
					background-color: #F3F3F3;
					position: absolute;
					top: 0;
					right: calc(100% - 1.888rem);
					height: 100%;
					width: 100vw;
					z-index: 2;
				}
				.swiper-navigation{
					.swiper-button-prev{
						left: -5.5rem;
					}
					.swiper-button-next{
						left: -3rem;
					}
				}
			}
		}
	}
}
