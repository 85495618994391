.ce_rsce_filterIcons {
    padding: $paddingMobile;
    text-align: center;
    max-width: 950px;
    margin: 0 auto;
    .container {
        h2 {
            &::after {
                left: 50%;
                transform: translate3d(-50%, 50%, 0);
            }
        }
        p {
            color: $primary;
            font-weight: bold;
            position: relative;
            &:hover {
                cursor: pointer;
            }
            &:after {
                content:"";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -2rem;
                width: 30px;
                height: 25px;
                background-image: url('/files/themes/rula-brw/images/icons/arrowDownBlue.svg');
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
        .container-inner {
            .icons{
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                gap: 2rem;
                .icon {
                    flex: calc(16.66% - 2rem);
                    width: calc(16.66% - 2rem);
                    min-width: 100px;
                    &.active {
                        img {
                            opacity: 1;
                        }
                    }
                    img {
                        transition: all .5s;
                        opacity: .5;
                      
                    }
                    &:hover {
                        cursor: pointer;
                        img {
                            transform: scale(1.05);
                        }
                    }
                   
                }
            }
        }
    }
}


@mixin rsce_filterIcons_tablet {
    .ce_rsce_filterIcons {
        padding: 0rem 2rem 3rem 2rem;
        .container {
            .container-inner {

            }
        }
    }
}