.ce_rsce_contentMittig {
    .container {
        padding: $paddingMobile;
        max-width: 30rem;
        margin: 0 auto;
        .container-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
            p {
                text-align: center;
            }
        }
    }
}


@mixin rsce_contentMittig_tabelt {
    .ce_rsce_contentMittig {
        .container {
            padding: $paddingDesktop;
        }
    }
}
