.ce_rsce_icons {
    max-width: $maxWidth;
    margin: 0 auto;
    padding: $paddingMobile;
    .container {
        .contianer-inner.icons {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1rem;
            .icon {
                flex: calc(33% - 1rem);
                width: calc(33% - 1rem);
                min-width: 220px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                h3 {
                    font-family: 'Mont';
                    margin-bottom: .5rem;
                    font-weight: 700;
                }
                p {
                    margin: 0;
                    text-align: center;
                }
            }
        }
    }
}


@mixin rsce_icons_tablet {
    .ce_rsce_icons {
        padding: $paddingDesktop;
        .container {
            .container-inner {
                .headline {
                    width: 50%;
                    min-width: 250px;
                }
            }
            .contianer-inner.icons {
                margin-top: 5rem;
            }
        }
    }
}